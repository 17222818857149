<template>
  <div class="text-center">
    <v-menu
      v-model="showLogin"
      :close-on-content-click="false"
      anchor="start"
    >
      <template v-slot:activator="{ props }">
        <v-btn
          color="primary"
          v-bind="props"
        >
          Login
          <v-icon right>mdi-login</v-icon>
        </v-btn>
      </template>

      <v-card min-width="300">
        <v-list color="teal darken-4">
          <p class="text-h6 text-center">
            Login
          </p>
        </v-list>

        <v-divider></v-divider>

        <v-list color="teal darken-4">
          <v-list-item>
            <v-text-field
            label="Username"
            variant="outlined"
            autofocus
            v-model="username"
          ></v-text-field>
          </v-list-item>

          <v-list-item>
            <v-text-field
            label="Password"
            variant="outlined"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"
            v-model="password"
            @keyup.enter="performLogin"
          ></v-text-field>
          </v-list-item>
        </v-list>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            @click="closeLogin"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="performLogin"
          >
            Login
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  data: () => ({
    showLogin: false,
    show1: false,
    show2: true,
    username: '',
    password: '',
    rules: {
      required: value => !!value || 'Required.'
    }
  }),
  methods: {
    closeLogin () {
      this.username = ''
      this.password = ''
      this.showLogin = false
    },
    performLogin () {
      this.$store.dispatch('login', {
        Username: this.username,
        Password: this.password
      })
        .then(() => {
          this.$store.dispatch('showCustomToastAsync', { color: 'success', message: 'Logged in!' })
          this.closeLogin()
        })
        .catch(error => {
          this.$store.dispatch('showCustomToastAsync', { color: 'red', message: 'Failed to log in...' })
          console.log(error)
        })
    }
  }
}
</script>
