import axios from 'axios'

const getDefaultState = () => {
  return {
    id: -1,
    username: '',
    email: '',
    profilePictureLink: ''
  }
}

const state = {
  id: +localStorage.getItem('user.id') || -1,
  username: localStorage.getItem('user.username') || '',
  profilePictureLink: localStorage.getItem('user.profilePictureLink') || 'https://i.imgur.com/6Wddapm.png',
  email: localStorage.getItem('user.email') || ''
}
const actions = {
  async getUser ({ commit }, userId) {
    await axios.get('/api/users?$filter=Id eq ' + userId)
      .then(resp => {
        const userData = resp.data.value[0]
        delete userData.Password
        commit('storeUserData', userData)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  async updateUser ({ commit }, userData) {
    await axios.patch('/api/users(' + userData.userId + ')', userData.payload)
      .then(resp => {
        const userData = resp.data
        delete userData.Password
        commit('storeUserData', userData)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }
}
const mutations = {
  storeUserData (state, userData) {
    localStorage.setItem('user.id', userData.Id)
    localStorage.setItem('user.username', userData.Username)
    localStorage.setItem('user.email', userData.Email)
    localStorage.setItem('user.profilePictureLink', userData.ProfilePictureLink)
    state.id = userData.Id
    state.username = userData.Username
    state.email = userData.Email
    state.profilePictureLink = userData.ProfilePictureLink
  },
  resetLoggedInUserData (state) {
    const resetData = getDefaultState()
    localStorage.setItem('user.id', resetData.id)
    localStorage.setItem('user.username', resetData.username)
    localStorage.setItem('user.email', resetData.email)
    localStorage.setItem('user.profilePictureLink', resetData.profilePictureLink)
    state.id = resetData.id
    state.username = resetData.username
    state.email = resetData.email
    state.profilePictureLink = resetData.profilePictureLink
  }
}
const getters = {
  getUserData: (state) => state
}

export default {
  state,
  actions,
  mutations,
  getters
}
