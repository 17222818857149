<template>
  <v-container class="d-flex flex-column text-center">
    <div class="text-h4">
      <p>Welcome to the website of Mike Schoen!</p>
    </div>
    <div class="pt-5 text-h6">
      <p>This site is still a work in progress, more to come soon!</p>
      <p>Designed in Vue 3 and .Net 6</p>
      <p>Utilizing Vuetify, Vuex, OData, and Entity Framework</p>
      <p>Hosted on Azure and published with Github Actions</p>
    </div>
  </v-container>
</template>

<script>
import { defineComponent } from 'vue'

// Components

export default defineComponent({
  name: 'HomeView',

  components: {
  }
})
</script>
