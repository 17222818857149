<template>
  <v-app theme="dark" class="fill-view-height">
    <NavBar/>
    <CustomToast/>
    <v-main class="fill-main">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from '@/components/Navigation/NavBar'
import CustomToast from '@/components/Toast/CustomToast'

export default {
  name: 'App',
  components: {
    NavBar,
    CustomToast
  },
  data: () => ({})
}
</script>
