import { createStore } from 'vuex'
import authentication from './modules/authentication'
import customToast from './modules/customToast'
import user from './modules/user'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    authentication,
    customToast,
    user
  }
})
