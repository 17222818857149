<template>
  <v-container class="d-flex flex-column text-center">
    <div class="text-h4">
      <p>Gonna put info on the website author here!</p>
    </div>
    <div class="pt-5 text-h6">
      <p>This page is still a work in progress, more to come soon!</p>
    </div>
  </v-container>
</template>

<script>
import { defineComponent } from 'vue'

// Components

export default defineComponent({
  name: 'AboutView',

  components: {
  }
})
</script>
