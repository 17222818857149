import axios from 'axios'

const getDefaultState = () => {
  return {
    token: '',
    tokenExpirationDate: new Date()
  }
}

const state = {
  tokenExpirationDate: localStorage.getItem('user.tokenExpirationDate') || new Date(),
  token: localStorage.getItem('token') || ''
}
const actions = {
  async register ({ dispatch }, userData) {
    await axios.post('/api/registration', userData)
      .then(() => {
        const loginData = {
          Username: userData.Username,
          Password: userData.Password
        }
        dispatch('login', loginData)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  async login ({ commit, dispatch }, loginData) {
    await axios.post('/api/login', loginData)
      .then(resp => {
        const authData = resp.data
        axios.defaults.headers.common.Authorization = authData.Token
        commit('loginSuccessful', authData)
        return authData.Id
      })
      .then(id => {
        dispatch('getUser', id)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  async logout ({ commit }) {
    commit('logoutAndResetSession')
    commit('resetLoggedInUserData')
  }
}
const mutations = {
  loginSuccessful (state, authData) {
    localStorage.setItem('tokenExpirationDate', authData.TokenExpirationDate)
    localStorage.setItem('token', authData.Token)
    state.token = authData.Token
    state.tokenExpirationDate = authData.TokenExpirationDate
  },
  logoutAndResetSession (state) {
    const resetData = getDefaultState()
    localStorage.setItem('tokenExpirationDate', resetData.TokenExpirationDate)
    localStorage.setItem('token', resetData.token)
    state.token = resetData.token
    state.tokenExpirationDate = resetData.TokenExpirationDate
  }
}
const getters = {
  isAuthenticated: (state) => !!state.token
}

export default {
  state,
  actions,
  mutations,
  getters
}
