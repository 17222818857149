const state = {
  show: false,
  color: 'success',
  message: '',
  icon: 'mdi-alert-circle-outline',
  timeout: 3000
}
const actions = {
  showCustomToastAsync ({ commit }, customToast) {
    commit('setCustomToast', {
      show: true,
      color: customToast.color,
      message: customToast.message
    })
    setTimeout(() => {
      commit('hideCustomToast')
    }, state.timeout + 1000)
  },
  resetCustomToastState ({ commit }) {
    commit('hideCustomToast')
  }
}
const mutations = {
  setCustomToast (state, customToast) {
    state.color = customToast.color
    state.message = customToast.message
    state.show = customToast.show
  },
  hideCustomToast (state) {
    state.show = false
    state.color = 'success'
    state.message = ''
  }
}
const getters = {
  getCustomToastShow (state) {
    return state.show
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
