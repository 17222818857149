<template>
  <nav>
    <v-app-bar flat>
      <v-toolbar-title>
        <span class="class font-weight-light">Mike Schoen's Homepage</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <LoginMenu v-if="!isAuthenticated" />
      <RegisterMenu v-if="!isAuthenticated" />
      <v-btn
        v-if="isAuthenticated"
        color="primary"
        @click="performLogout"
      >
        Logout
        <v-icon right>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      :rail="rail"
      expand-on-hover
      permanent
      app
    >
      <v-list-item
        :prepend-avatar="userData.profilePictureLink"
        :title="userData.username"
      >
      </v-list-item>

      <v-divider></v-divider>

      <v-list density="compact" nav>
        <v-list-item v-for="link in filterAuthenticatedLinks" :key="link.title" :prepend-icon="link.icon" :title="link.title" router :to="link.route"></v-list-item>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import LoginMenu from '@/components/Authentication/LoginMenu'
import RegisterMenu from '@/components/Authentication/RegisterMenu'
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    links: [
      { title: 'Home', icon: 'mdi-home-city', route: '/', needsAuthentication: false },
      { title: 'My Account', icon: 'mdi-account', route: '/account', needsAuthentication: true },
      { title: 'Lists', icon: 'mdi-note-text-outline', route: '/lists', needsAuthentication: true },
      { title: 'Characters', icon: 'mdi-account-group-outline', route: '/characters', needsAuthentication: true },
      { title: 'About Mike Schoen', icon: 'mdi-information-outline', route: '/about', needsAuthentication: false }
    ],
    unknownUserData: {
      username: 'Unknown User',
      profilePictureLink: 'https://i.imgur.com/6Wddapm.png'
    },
    rail: true
  }),
  computed: {
    ...mapGetters([
      'isAuthenticated',
      'getUserData'
    ]),
    filterAuthenticatedLinks () {
      return this.isAuthenticated ? this.links : this.links.filter(i => i.needsAuthentication === false)
    },
    userData () {
      return this.getUserData.id !== -1 ? this.getUserData : this.unknownUserData
    }
  },
  components: {
    LoginMenu,
    RegisterMenu
  },
  methods: {
    performLogout () {
      this.$store.dispatch('logout')
        .then(() => {
          this.$store.dispatch('showCustomToastAsync', { color: 'success', message: 'You have been logged out!' })
          this.$router.push('/')
        })
        .catch(error => {
          this.$store.dispatch('showCustomToastAsync', { color: 'red', message: 'An unexpected error has occurred.' })
          console.log(error)
        })
    }
  }
}
</script>
