<template>
  <v-snackbar
      :color="CustomToastColor"
      :timeout="CustomToastTimer"
      v-model="show"
      top
  >
    <v-icon left>{{CustomToastIcon}}</v-icon>{{CustomToastMessage}}
  </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CustomToast',
  data () {
    return {
      show: false
    }
  },
  watch: {
    getCustomToastShow: function () {
      this.show = this.getCustomToastShow
    }
  },
  computed: {
    ...mapGetters([
      'getCustomToastShow'
    ]),
    CustomToastTimer () {
      return this.$store.state.customToast.timeout
    },
    CustomToastColor () {
      return this.$store.state.customToast.color
    },
    CustomToastMessage () {
      return this.$store.state.customToast.message
    },
    CustomToastIcon () {
      return this.$store.state.customToast.icon
    }
  }
}
</script>
