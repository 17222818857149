import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createApp } from 'vue'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/lib/components'
import * as directives from 'vuetify/lib/directives'
import { fa } from 'vuetify/lib/iconsets/fa'
import { mdi } from 'vuetify/lib/iconsets/mdi'
import { md } from 'vuetify/lib/iconsets/md'
import { mdi as mdiSvg } from 'vuetify/lib/iconsets/mdi-svg'
import App from './App.vue'
import router from './router'
import store from './store'
import { loadFonts } from './plugins/webfontloader'
import './styles/style.sass'
import Axios from 'axios'

// set auth header
Axios.defaults.headers.common.Authorization = `Bearer ${store.state.authentication.token}`

loadFonts()

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    sets: {
      fa,
      mdi,
      md,
      mdiSvg
    }
  }
})

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .mount('#app')
