<template>
  <div class="text-center">
    <v-menu
      v-model="showRegistration"
      :close-on-content-click="false"
      anchor="start"
    >
      <template v-slot:activator="{ props }">
        <v-btn
          color="primary"
          v-bind="props"
        >
          Register
          <v-icon right>mdi-account-plus-outline</v-icon>
        </v-btn>
      </template>

      <v-card min-width="300">
        <v-list color="teal darken-4">
          <p class="text-h6 text-center">
            Register
          </p>
        </v-list>

        <v-divider></v-divider>

        <v-list color="teal darken-4">
          <v-list-item>
            <v-text-field
            label="Username"
            variant="outlined"
            v-model="username"
          ></v-text-field>
          </v-list-item>

          <v-list-item>
            <v-text-field
            label="Email"
            variant="outlined"
            v-model="email"
          ></v-text-field>
          </v-list-item>

          <v-list-item>
            <v-text-field
            label="Profile Picture Link"
            variant="outlined"
            v-model="profilePictureLink"
          ></v-text-field>
          </v-list-item>

          <v-list-item>
            <v-text-field
              label="Access Code"
              variant="outlined"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show3 ? 'text' : 'password'"
              counter
              @click:append="show3 = !show3"
              v-model="accessCode"
            ></v-text-field>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list color="teal darken-4">
          <v-list-item>
            <v-text-field
              label="Password"
              variant="outlined"
              hide-details="auto"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="show1 ? 'text' : 'password'"
              hint="At least 8 characters"
              persistent-hint
              counter
              @click:append="show1 = !show1"
              v-model="password1"
            ></v-text-field>
          </v-list-item>

          <v-list-item>
            <v-text-field
              label="Confirm Password"
              variant="outlined"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="show2 ? 'text' : 'password'"
              counter
              @click:append="show2 = !show2"
              v-model="password2"
              @keyup.enter="performRegistration"
            ></v-text-field>
          </v-list-item>
        </v-list>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            @click="closeRegistration"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="performRegistration"
          >
            Register
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  data: () => ({
    showRegistration: false,
    show1: false,
    show2: false,
    show3: false,
    rules: {
      required: value => !!value || 'Required.',
      min: v => v.length >= 8 || 'Min 8 characters'
    },
    username: '',
    email: '',
    profilePictureLink: '',
    accessCode: '',
    password1: '',
    password2: ''
  }),
  methods: {
    closeRegistration () {
      this.username = ''
      this.email = ''
      this.profilePictureLink = ''
      this.accessCode = ''
      this.password1 = ''
      this.password2 = ''
      this.showRegistration = false
    },
    performRegistration () {
      if (this.password1 !== this.password2) {
        this.$store.dispatch('showCustomToastAsync', { color: 'red', message: 'Passwords must match' })
      } else {
        this.$store.dispatch('register', {
          Username: this.username,
          Email: this.email,
          ProfilePictureLink: this.profilePictureLink,
          AccessCode: this.accessCode,
          Password: this.password1
        })
          .then(() => {
            this.$store.dispatch('showCustomToastAsync', { color: 'success', message: 'Logged in!' })
            this.closeRegistration()
          })
          .catch(error => {
            this.$store.dispatch('showCustomToastAsync', { color: 'red', message: error })
            console.log(error)
          })
      }
    }
  }
}
</script>
